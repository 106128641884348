<template>
  <div id="set-create-details-tab" @keyup.ctrl.enter="set_update_data">
    <div class="vx-col w-full">
      <div class="vx-row">
        <div class="vx-col w-full mb-6">
          <vs-input v-model="dataLocal.set_id" class="w-full" label-placeholder="Set ID" readonly />
        </div>
        <div class="vx-col sm:w-1/2 w-full mb-6">
          <vs-input v-model="dataLocal.set_name" class="w-full" label-placeholder="Set Name" />
        </div>
        <div class="vx-col sm:w-1/2 w-full mb-6">
          <vs-select class="w-full mt-5" placeholder="Set Status" v-model="dataLocal.set_status">
            <vs-select-item :key="index" :value="option" :text="option | capitalize" v-for="(option, index) in statusOptions" />
          </vs-select>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input class="w-full" label-placeholder="Thumbnail URL" v-model="dataLocal.set_thumbnail" />
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-textarea
            v-model="dataLocal.set_description"
            :counter-danger.sync="set_description_counter"
            counter="400"
            height="100px"
            label="Set Description"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button class="mb-2 mr-2" color="success" type="filled" @click="set_update_data">Update Set </vs-button>
          <vs-button class="mb-2 ml-2" color="warning" type="border" @click="reset_data">Reset </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      set_description_counter: null,

      statusOptions: ['active', 'hidden', 'disabled'],
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    set_update_data() {
      this.$emit('update_setData', this.dataLocal);
    },
    reset_data() {
      this.dataLocal = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>

<style scoped></style>
