<!-- =========================================================================================
  File Name: EditDomain.vue
  Description: User Edit Page

========================================================================================== -->

<template>
  <div id="page-user-edit">

    <vs-alert color="danger" title="Set Not Found" :active.sync="errorOccurred">
      <span>Set record with id: {{ $route.params.setId }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{name:'admin-list-flashcard-set'}"
                                        class="text-inherit underline">All Sets</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="setData">

      <div slot="no-body" class="tabs-container px-6 pt-6">

        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Details" icon-pack="feather" icon="icon-edit">
            <div class="tab-text">
              <set-details-tab class="mt-4" :data="setData" v-on:update_setData="update_setData"/>
            </div>
          </vs-tab>
          <!---    <vs-tab label="Information" icon-pack="feather" icon="icon-info">
            <div class="tab-text">
          <user-edit-tab-information class="mt-4" :data="user_data" />
            </div>
          </vs-tab> --->
          <!---  <vs-tab label="Social" icon-pack="feather" icon="icon-share-2">
          <div class="tab-text">
          <user-edit-tab-social class="mt-4" :data="user_data" />
            </div>
          </vs-tab> --->
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import SetDetailsTab from './SetDetailsTab.vue'


export default {
  components: {
    SetDetailsTab
  },
  data () {
    return {
      setData: null,
      errorOccurred: false,

      activeTab: 0
    }
  },
  methods: {
    loadSetData () {
      this.$http.get(`flashcards/sets/${this.$route.params.setId}`)
        .then(response => {
          if (response.status === 200) {
            this.setData = {}
            this.setData.set_id = response.data.data.id
            this.setData.set_name = response.data.data.name
            this.setData.set_description = response.data.data.description
            this.setData.set_status = response.data.data.status
            this.setData.set_thumbnail = response.data.data.thumbnail
            this.setData.set_practice_enabled = response.data.data.practice_enabled
            this.setData.set_flashcard_enabled = response.data.data.flashcard_enabled
          }

        })
        .catch(error => {
          this.errorOccurred = true
          this.error_msg = error
        })
    },
    update_setData (data) {
      const pl = {
        name: data.set_name,
        code: data.set_code,
        description: data.set_description,
        thumbnail: data.set_thumbnail,
        practice_enabled: data.set_practice_enabled,
        flashcard_enabled: data.set_flashcard_enabled,
        status: data.set_status === undefined ? 'active' : data.set_status
      }

      if (pl.name === undefined) {
        return this.$vs.notify({
          title: 'Failed to update set',
          text: 'Please provide a Set Name',
          color: 'danger',
          position: 'top-right'
        })
      }


      this.$vs.loading()

      this.$http.post(`flashcards/sets/${this.$route.params.setId}`, pl)
        .then(response => {

          if (response.status === 200) {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Updated Set Successfully',
              text: `Set ${pl.name} has been updated.`,
              color: 'success',
              position: 'top-right'
            })
            return this.loadSetData()
          }

        })
        .catch(exception => {
          this.$vs.loading.close()

          let error = 'An unknown error occurred while updating this set'
          if (exception.response) {
            error = exception.response.data.error.description
          }

          return this.$vs.notify({
            title: 'Failed to update set',
            text: error,
            color: 'danger',
            position: 'top-right'
          })
        })
    }
  },
  created () {
    this.loadSetData(this.$route.params.setId)
  }
}

</script>
